import { format, parse, addHours, isBefore } from 'date-fns';

export const formatToHours = date => format(date, 'HH:mm');
export const formatToDate = hours => parse(hours, 'HH:mm', new Date());

export const addHoursOnTimeStrings = (quantity, hour) =>
  formatToHours(addHours(formatToDate(hour), quantity));

export const isBeforeOnTimeStrings = (date1, date2) =>
  isBefore(formatToDate(date1), formatToDate(date2));
