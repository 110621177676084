import React from 'react';
import { Site, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import routes from '../../utils/routes';

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Site.Footer
      copyright={
        <>
          <Button
            link
            RootComponent="a"
            href={routes.generalConditions}
            className="d-md-none"
          >
            {t('dashboard.navigation.general_conditions')}
          </Button>
          <p
            className="text-lg-left"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('contact', { email: 'help@wearehitch.com' }),
            }}
          />
        </>
      }
      nav={
        <Button
          link
          RootComponent="a"
          href={routes.generalConditions}
          target="_blank"
          className="d-none d-md-block"
        >
          {t('dashboard.navigation.general_conditions')}
        </Button>
      }
    ></Site.Footer>
  );
};

export default Footer;
