import React, { useContext, useState, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Button } from 'tabler-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { coachCreateOfferRequest, sportsRequest } from '../utils/apiClient';
import UserContext from '../context/UserContext';
import routes from '../utils/routes';

import CreateOfferForm from './offer/CreateOfferForm';
import LoadingData from './layout/LoadingData';

const CreateOfferPage = () => {
  const { setMessage } = useContext(UserContext);
  const history = useHistory();
  const [t] = useTranslation();

  const [sports, setSports] = useState([]);
  const [isLoadingSports, setIsLoadingSports] = useState(true);
  const [loading, setLoading] = useState(false);

  const createOffer = async data => {
    try {
      setLoading(true);
      await coachCreateOfferRequest(data.sportId)(data);

      setLoading(false);
      history.push(routes.listingOffer);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '12' });
      setLoading(false);
    }
  };

  useEffect(() => {
    const sportsHandler = async () => {
      const response = await sportsRequest();
      setSports(response.content);
      setIsLoadingSports(false);
    };

    sportsHandler();
  }, []);

  return (
    <>
      {isLoadingSports && <LoadingData />}
      {!isLoadingSports && (
        <Page.Content>
          <Button
            onClick={() => history.push(routes.listingOffer)}
            icon="arrow-left"
            color="secondary"
            className="mb-4"
          >
            {t('dashboard.back')}
          </Button>
          <Card>
            <Card.Body>
              <CreateOfferForm
                handleClick={createOffer}
                sports={sports}
                loading={loading}
              />
            </Card.Body>
          </Card>
        </Page.Content>
      )}
    </>
  );
};

export default CreateOfferPage;
