import React, { useContext, useEffect, useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import UserContext from '../context/UserContext';
import { getOffersRequest, getBookingsRequest } from '../utils/apiClient';

import IncompleteOnboardingReminder from './dashboard/IncompleteOnboardingReminder';
import BookingsList from './dashboard/BookingsList';
import LoadingData from './layout/LoadingData';

const DashboardContent = () => {
  const { userData } = useContext(UserContext);
  const [offers, setOffers] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const [showReminder, setShowReminder] = useState(false);

  useEffect(() => {
    const getData = async () => {
      const offersRequest = await getOffersRequest();
      setOffers(offersRequest.content);

      const bookingsRequest = await getBookingsRequest();
      setBookings(bookingsRequest.content);

      setIsLoadingData(false);
    };

    getData();
  }, []);

  useEffect(() => {
    setShowReminder(
      !userData.description ||
        !userData.payable ||
        !userData.hasCertifiedSports ||
        offers.length === 0
    );
  }, [offers, userData]);

  const [t] = useTranslation();

  return (
    <>
      {!userData && isLoadingData ? (
        <LoadingData />
      ) : (
        <>
          <Page.Content
            title={t('dashboard.title', { name: userData.firstname })}
          >
            <Card>
              <Card.Body>
                {showReminder && (
                  <IncompleteOnboardingReminder
                    isProfileComplete={!!userData.description}
                    isStripePayable={userData.payable}
                    hasOffers={offers.length > 0}
                    hasCertifiedSports={userData.hasCertifiedSports}
                  />
                )}
              </Card.Body>
            </Card>

            <Card>
              <Card.Body>
                <BookingsList bookings={bookings} />
              </Card.Body>
            </Card>
          </Page.Content>
        </>
      )}
    </>
  );
};

export default DashboardContent;
