import React, { useState, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Nav, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { remove, update, last } from 'ramda';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import config from '../../config/config';
import { getDefaultAvailabilities } from '../../utils/apiClient';
import {
  addHoursOnTimeStrings,
  isBeforeOnTimeStrings,
} from '../../utils/dates';
import routes from '../../utils/routes';
import LoadingButton from '../layout/LoadingButton';

import TimeframeForm from './TimeframeForm';

const AverageWeek = ({
  onSubmit,
  loading,
  isOnboarding,
  setAvailabilities,
  availabilities,
}) => {
  const [t] = useTranslation();
  const history = useHistory();

  const days = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
  ];

  const [currentDay, setCurrentDay] = useState(days[0]);

  useEffect(() => {
    const getAvailabilities = async () => {
      const { content } = await getDefaultAvailabilities();

      setAvailabilities(content);
    };

    getAvailabilities();
  }, [setAvailabilities]);

  const getNextTimeframe = () => {
    const timeframe = last(availabilities[currentDay].timeframes);

    if (
      timeframe &&
      isBeforeOnTimeStrings(timeframe.endAt, config.endHours.max)
    ) {
      return {
        startAt: timeframe.endAt,
        endAt: addHoursOnTimeStrings(1, timeframe.endAt),
      };
    }

    return {
      startAt: '08:00',
      endAt: '09:00',
    };
  };

  const deleteTimeframe = key => () => {
    setAvailabilities({
      ...availabilities,
      [currentDay]: {
        ...availabilities[currentDay],
        timeframes: remove(key, 1, availabilities[currentDay].timeframes),
      },
    });
  };

  const updateTimeframe = key => timeframe => {
    setAvailabilities({
      ...availabilities,
      [currentDay]: {
        ...availabilities[currentDay],
        timeframes: update(
          key,
          timeframe,
          availabilities[currentDay].timeframes
        ),
      },
    });
  };

  const addTimeframe = () => {
    setAvailabilities({
      ...availabilities,
      [currentDay]: {
        ...availabilities[currentDay],
        timeframes: [
          ...availabilities[currentDay].timeframes,
          getNextTimeframe(),
        ],
      },
    });
  };

  return (
    <>
      <Nav className="days-nav">
        {days.map(day => (
          <Nav.Item
            value={day}
            active={day === currentDay}
            key={day}
            onClick={() => setCurrentDay(day)}
          >
            {t(`average_week.labels.${day}`)}
          </Nav.Item>
        ))}
      </Nav>
      <div className="mt-3">
        {availabilities[currentDay].timeframes.length === 0 && (
          <p>{t('average_week.no_timeframes')}</p>
        )}
        {availabilities[currentDay].timeframes.map((timeframe, key) => (
          <TimeframeForm
            onDelete={deleteTimeframe(key)}
            onChange={updateTimeframe(key)}
            startAt={timeframe.startAt}
            endAt={timeframe.endAt}
            key={key}
          />
        ))}
      </div>
      <div className="mb-5">
        <Button color="secondary" size="sm" icon="plus" onClick={addTimeframe}>
          {t('average_week.add_timeframe')}
        </Button>
      </div>
      {days.indexOf(currentDay) < 6 && (
        <Button
          color="primary"
          onClick={() => setCurrentDay(days[days.indexOf(currentDay) + 1])}
        >
          {t(`average_week.next_day`)}
        </Button>
      )}
      {days.indexOf(currentDay) === 6 && (
        <Button
          color="primary"
          onClick={() => onSubmit(availabilities)}
          disabled={loading}
        >
          {loading && <LoadingButton />}
          {!loading && t('average_week.link.save')}
        </Button>
      )}
      {isOnboarding && (
        <Button.List align="center" className="mt-8">
          <Button
            onClick={() => history.push(routes.onboardingCreateOffer)}
            link
          >
            {t('average_week.link.skip')}
          </Button>
        </Button.List>
      )}
    </>
  );
};

AverageWeek.propTypes = {
  onSubmit: PropTypes.func,
  loading: PropTypes.bool,
  isOnboarding: PropTypes.bool,
  setAvailabilities: PropTypes.func,
  availabilities: PropTypes.object,
};

AverageWeek.defaultProps = {
  isOnboarding: false,
};

export default AverageWeek;
