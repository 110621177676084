import React, { useEffect, useState } from 'react';
import { Page, Card, Header } from 'tabler-react';
import { format, parse } from 'date-fns';
import 'tabler-react/dist/Tabler.css';
import { useTranslation } from 'react-i18next';

import { getGeneralConditionsRequest } from '../utils/apiClient';

import LoadingData from './layout/LoadingData';

/* eslint-disable react/no-danger */
const GeneralConditions = () => {
  const [t] = useTranslation();
  const [generalConditions, setGeneralConditions] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getGeneralConditions = async () => {
      const response = await getGeneralConditionsRequest();

      setGeneralConditions(response);
      setLoading(false);
    };

    getGeneralConditions();
  }, []);

  return (
    <>
      {!loading && (
        <Page.Content>
          <Card>
            <Card.Body>
              <Header.H1>{t('general_conditions.title')}</Header.H1>
              <Header.H2>{t('general_conditions.subTitle')}</Header.H2>
              <div
                dangerouslySetInnerHTML={{ __html: generalConditions.content }}
              />
              <p className="small">
                {t('general_conditions.date')}
                {format(
                  parse(generalConditions.date, 'yyyy-mm-dd', new Date()),
                  'dd.mm.yyyy'
                )}
                .
              </p>
            </Card.Body>
          </Card>
        </Page.Content>
      )}
      {loading && <LoadingData />}
    </>
  );
};

export default GeneralConditions;
