import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Form } from 'tabler-react';
import { useFormik } from 'formik';
import { object, string, number, array } from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LoadingButton from '../layout/LoadingButton';

import '../../assets/css/registrationForm.css';
import LocationsInput from './LocationsInput';

const UpdateOfferForm = ({
  handleClick,
  handleDisableOffer,
  offer,
  loading,
}) => {
  const [t] = useTranslation();

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      price: offer.price / 100,
      locations: offer.locations,
    },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        price: number()
          .typeError(t('offer.create.price.validation_format'))
          .required(t('offer.create.price.validation')),
        locations: array()
          .of(string().required(t('offer.create.locations.validation')))
          .required(t('offer.create.locations.validation'))
          .min(1, t('offer.create.locations.validation')),
      }),
    onSubmit: data => {
      handleClick({
        ...data,
        locations: data.locations.filter(
          locationsInput => locationsInput.location !== ''
        ),
        price: Math.round(data.price * 100),
        duration: parseInt(offer.duration),
        type: 'individual',
      });
    },
  });

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <p>
          <span className="font-weight-bold">
            {t('offer.create.sport.label')}:
          </span>{' '}
          {offer.sport.name}
        </p>
        <p>
          <span className="font-weight-bold">
            {t('offer.create.type.label')}:{' '}
          </span>
          {t('offer.create.type.checkbox.label')}
        </p>

        <p>
          <span className="font-weight-bold">
            {t('offer.create.duration.label')}:
          </span>{' '}
          {offer.duration}
        </p>

        <Form.Group label={t('offer.create.price.label')}>
          <Form.Input
            name="price"
            feedback={errors.price && touched.price ? errors.price : null}
            invalid={!!(errors.price && touched.price)}
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>

        <Form.Group label={t('offer.create.locations.label')}>
          <LocationsInput
            defaultLocations={offer.locations}
            onChange={locations => setFieldValue('locations', locations)}
            onBlur={handleBlur}
          />
          {errors.locations && touched.locations && (
            <p className="invalid-feedback d-block">{errors.locations}</p>
          )}
        </Form.Group>

        <Button color="primary" disabled={loading} type="submit">
          {loading ? <LoadingButton /> : t('offer.update.submit')}
        </Button>
      </Form>
      <Button.List align="center" className="mt-8">
        <Button onClick={handleDisableOffer} link className="text-danger">
          {t('offer.update.link')}
        </Button>
      </Button.List>
    </>
  );
};

UpdateOfferForm.propTypes = {
  offer: PropTypes.object,
  handleClick: PropTypes.func,
  handleDisableOffer: PropTypes.func,
  loading: PropTypes.bool,
};

export default UpdateOfferForm;
