import React, { useState, useEffect, useContext } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Table, Button, Header } from 'tabler-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { remove } from 'ramda';

import { getOffersRequest, disableOfferRequest } from '../utils/apiClient';
import routes from '../utils/routes';
import UserContext from '../context/UserContext';

import LoadingData from './layout/LoadingData';

const OfferListingPage = () => {
  const { setMessage } = useContext(UserContext);
  const [isLoadingOffers, setIsLoadingOffers] = useState(true);
  const [offers, setOffers] = useState([]);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    const offersHandler = async () => {
      const response = await getOffersRequest();
      setOffers(response.content);

      setIsLoadingOffers(false);
    };

    offersHandler();
  }, []);

  const handleEditOffer = offerId => {
    history.push(`/offers/${offerId}`);
  };

  const handleDisableOffer = async (offerId, index) => {
    try {
      await disableOfferRequest(offerId);
      setOffers(remove(index, 1, offers));
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '17' });
    }
  };

  return (
    <>
      {isLoadingOffers && <LoadingData />}
      {!isLoadingOffers && (
        <Page.Content>
          <Card>
            <Card.Body>
              <div className="px-2">
                <Header.H4 className="mr-2">
                  {t('offer.listing.title')}
                </Header.H4>
              </div>
              <Table>
                <Table.Body>
                  {offers.map((offer, i) => (
                    <Table.Row key={i}>
                      <Table.Col>
                        <div className="d-md-flex">
                          <div className="d-flex w-100">
                            <p className="font-weight-bold m-0 w-100">
                              {offer.sport.name}
                            </p>
                            <div className="w-100">
                              <p className="m-0">{offer.price / 100} CHF</p>
                              <p className="m-0">{offer.duration} minutes</p>
                              <p className="m-0">
                                {offer.locations.join(', ')}
                              </p>
                            </div>
                          </div>
                          <Button.List
                            align="center"
                            className="mt-4 d-flex w-100"
                          >
                            <Button
                              onClick={() => handleEditOffer(offer.id)}
                              color="primary"
                              className="w-100"
                            >
                              {t('offer.listing.edit')}
                            </Button>
                            <Button
                              onClick={() => handleDisableOffer(offer.id, i)}
                              color="danger"
                              outline
                              className="w-100"
                            >
                              {t('offer.listing.disable')}
                            </Button>
                          </Button.List>
                        </div>
                      </Table.Col>
                    </Table.Row>
                  ))}
                </Table.Body>
              </Table>
            </Card.Body>
          </Card>
          <div style={{ maxWidth: '20rem' }} className="mx-auto mb-5">
            <Button
              onClick={() => history.push(routes.createOffer)}
              color="primary"
              className="w-100"
              style={{ maxWidth: '20rem' }}
            >
              {t('offer.listing.add')}
            </Button>
          </div>
        </Page.Content>
      )}
    </>
  );
};

export default OfferListingPage;
