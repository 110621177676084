import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Header, Button } from 'tabler-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import OnboardingSteps from './layout/OnboardingSteps';

const EndOnboardingPage = () => {
  const history = useHistory();
  const [t] = useTranslation();

  return (
    <>
      <OnboardingSteps />
      <Page.Content>
        <Card>
          <Card.Body>
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('end_onboarding.explanation'),
              }}
            />
            <hr />
            <Header.H4>{t('end_onboarding.documents.title')}</Header.H4>
            <ul>
              <li>{t('end_onboarding.documents.list.CV')}</li>
              <li>{t('end_onboarding.documents.list.diplomas')}</li>
              <li>{t('end_onboarding.documents.list.ID')}</li>
              <li>{t('end_onboarding.documents.list.bank_information')}</li>
              <li>{t('end_onboarding.documents.list.attestation_address')}</li>
              <ul>
                <li>{t('end_onboarding.documents.list.attestation_type')}</li>
                <ul>
                  <li>
                    {t(
                      'end_onboarding.documents.list.attestation_conditions.max_months'
                    )}
                  </li>
                  <li>
                    {t(
                      'end_onboarding.documents.list.attestation_conditions.different_than_ID'
                    )}
                  </li>
                  <li>
                    {t(
                      'end_onboarding.documents.list.attestation_conditions.complete'
                    )}
                  </li>
                </ul>
              </ul>
            </ul>
            <p>
              <strong>{t('end_onboarding.documents.warning_phone')}</strong>
            </p>
            <hr />
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('contact', { email: 'help@wearehitch.com' }),
              }}
            />
            <Button.List align="center" className="mt-8">
              <Button onClick={() => history.push('/')} color="primary">
                {t('end_onboarding.link.dashboard')}
              </Button>
            </Button.List>
          </Card.Body>
        </Card>
      </Page.Content>
    </>
  );
};

export default EndOnboardingPage;
