import React, { useReducer, useEffect, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import 'tabler-react/dist/Tabler.css';
import { auth } from 'firebase';
import { StandaloneFormPage, Card } from 'tabler-react';

import logo from '../../assets/images/logo.png';
import UserContext from '../../context/UserContext';
import routes from '../../utils/routes';
import GeneralConditions from '../GeneralConditions';

import LoginForm from './LoginForm';
import ConfirmationForm from './ConfirmationForm';

function reducer(state, action) {
  switch (action.type) {
    case 'setPhone':
      return { ...state, phone: action.payload };
    case 'setCode':
      return { ...state, code: action.payload };
    case 'setConfirmationCallback':
      return { ...state, confirmationCallback: action.payload };
    default:
      throw new Error();
  }
}

const initialState = {
  phone: '',
  code: '',
  confirmationCallback: null,
};

const NotLogged = () => {
  const { setMessage } = useContext(UserContext);
  const history = useHistory();
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    window.recaptchaVerifier = new auth.RecaptchaVerifier('captcha', {
      size: 'invisible',
    });

    // When changing page, ensure that message is null to avoid displaying an alert
    history.listen(() => {
      setMessage(null);
    });
  }, [history, setMessage]);

  useEffect(() => {
    const setConfirmationCallback = async () => {
      try {
        const payload = await auth().signInWithPhoneNumber(
          state.phone,
          window.recaptchaVerifier
        );

        dispatch({
          type: 'setConfirmationCallback',
          payload,
        });
        history.push(routes.loginConfirmation);
      } catch (e) {
        setMessage({ code: '01' });
      }
    };

    if (state.phone !== '') {
      setConfirmationCallback();
    }
  }, [state.phone, history, setMessage]);

  useEffect(() => {
    const finishLogin = async () => {
      try {
        await state.confirmationCallback.confirm(state.code);
        history.push('/');
      } catch (e) {
        setMessage({ code: '02' });
      }
    };

    if (state.code !== '' && state.confirmationCallback) {
      finishLogin();
    }
  }, [state.code, state.confirmationCallback, history, setMessage]);

  return (
    <>
      <div id="captcha" />
      <Switch>
        <Route
          exact
          path={routes.generalConditions}
          component={GeneralConditions}
        />
        <Route
          path={routes.loginConfirmation}
          render={props => (
            <StandaloneFormPage imageURL={logo}>
              <Card>
                <Card.Body className="p-6">
                  <ConfirmationForm
                    {...props}
                    handleClick={payload =>
                      dispatch({ type: 'setCode', payload })
                    }
                  />
                </Card.Body>
              </Card>
            </StandaloneFormPage>
          )}
        />
        <Route
          path="/"
          render={props => (
            <StandaloneFormPage imageURL={logo}>
              <Card>
                <Card.Body className="p-6">
                  <LoginForm
                    {...props}
                    handleClick={payload =>
                      dispatch({ type: 'setPhone', payload })
                    }
                  />
                </Card.Body>
              </Card>
            </StandaloneFormPage>
          )}
        />
      </Switch>
    </>
  );
};

export default NotLogged;
