import React, { useState, useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import * as firebase from 'firebase';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import 'tabler-react/dist/Tabler.css';
import { Page } from 'tabler-react';

import fr from '../locales/messages.fr.json';
import UserContext from '../context/UserContext';
import config from '../config/config';
import { coachMeRequest } from '../utils/apiClient';

import Dashboard from './Dashboard';
import NotLogged from './auth/NotLogged';
import LoadingData from './layout/LoadingData';
import Footer from './layout/Footer';
import Alert from './layout/Alert';

import '../assets/css/overrides.css';

firebase.initializeApp(config.firebaseConfig);

i18n.use(initReactI18next).init({
  fallbackLng: 'fr',
  debug: true,

  interpolation: {
    escapeValue: false,
  },
  resources: {
    fr: { translation: fr },
  },
});

const App = () => {
  const [user, setUser] = useState(null);
  const [userData, setUserData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState(null);

  const refreshUserData = async () => {
    const { content } = await coachMeRequest();

    setUserData(content);
  };

  useEffect(() => {
    firebase.auth().onAuthStateChanged(setUser);

    setTimeout(() => {
      setLoading(false);
    }, 1500);
  }, []);

  useEffect(() => {
    if (user) {
      setLoading(false);
    }
  }, [user]);

  return (
    <UserContext.Provider
      value={{
        userData,
        setUserData,
        refreshUserData,
        message,
        setMessage,
      }}
    >
      {message && <Alert onClick={() => setMessage(null)} message={message} />}
      <BrowserRouter>
        <Page>
          <Page.Main>
            {loading && <LoadingData />}
            {!loading && user && <Dashboard />}
            {!loading && !user && <NotLogged />}
          </Page.Main>
          {!loading && <Footer />}
        </Page>
      </BrowserRouter>
    </UserContext.Provider>
  );
};

export default App;
