import React, { useState, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Grid, Form } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { eachHourOfInterval, addHours } from 'date-fns';
import Select from 'react-select';
import PropTypes from 'prop-types';

import config from '../../config/config';
import { formatToHours, formatToDate } from '../../utils/dates';

const TimeframeForm = ({ onChange, onDelete, startAt, endAt }) => {
  const [t] = useTranslation();

  const createSelectEntry = date => ({
    value: date,
    label: formatToHours(date),
  });

  const [start, setStart] = useState(formatToDate(startAt));
  const [end, setEnd] = useState(formatToDate(endAt));

  const startInterval = {
    start: formatToDate(config.startHours.min),
    end: formatToDate(config.startHours.max),
  };

  const endInterval = {
    start: addHours(start, 1),
    end: formatToDate(config.endHours.max),
  };

  useEffect(() => {
    setStart(formatToDate(startAt));
    setEnd(formatToDate(endAt));
  }, [startAt, endAt]);

  return (
    <>
      <Grid.Row className="mt-3">
        <Grid.Col width={5}>
          <Form.Group
            label={t('average_week.from')}
            className="d-flex align-items-center"
          >
            <Select
              isSearchable={false}
              className="w-100 ml-2"
              id="startAt"
              value={createSelectEntry(start)}
              onChange={({ value }) =>
                onChange({
                  startAt: formatToHours(value),
                  endAt: formatToHours(value >= end ? addHours(value, 1) : end),
                })
              }
              options={eachHourOfInterval(startInterval).map(createSelectEntry)}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={5}>
          <Form.Group
            label={t('average_week.to')}
            className="d-flex align-items-center"
          >
            <Select
              isSearchable={false}
              className="w-100 ml-2"
              name="endAt"
              id="endAt"
              value={createSelectEntry(end)}
              onChange={({ value }) =>
                onChange({
                  startAt: formatToHours(start),
                  endAt: formatToHours(value),
                })
              }
              options={eachHourOfInterval(endInterval).map(createSelectEntry)}
            />
          </Form.Group>
        </Grid.Col>
        <Grid.Col width={2}>
          <Button color="danger" icon="trash" onClick={onDelete} />
        </Grid.Col>
      </Grid.Row>
    </>
  );
};

TimeframeForm.propTypes = {
  onDelete: PropTypes.func,
  onChange: PropTypes.func,
  startAt: PropTypes.string,
  endAt: PropTypes.string,
};

export default TimeframeForm;
