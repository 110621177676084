import React, { useContext, useState, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Button } from 'tabler-react';
import { useParams, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  updateOfferRequest,
  getOfferRequest,
  disableOfferRequest,
} from '../utils/apiClient';
import routes from '../utils/routes';
import UserContext from '../context/UserContext';

import UpdateOfferForm from './offer/UpdateOfferForm';
import LoadingData from './layout/LoadingData';

const UpdateOfferPage = () => {
  const { setMessage } = useContext(UserContext);
  const { offerId } = useParams();
  const history = useHistory();
  const [t] = useTranslation();

  const [isLoadingOffer, setIsLoadingOffer] = useState(true);
  const [offer, setOffer] = useState(null);
  const [loading, setLoading] = useState(false);

  const updateOffer = async data => {
    try {
      setLoading(true);
      await updateOfferRequest(offerId)(data);

      setLoading(false);
      history.push(routes.listingOffer);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '16' });
      setLoading(false);
    }
  };

  const handleDisableOffer = async () => {
    try {
      await disableOfferRequest(offerId);
      history.push(routes.listingOffer);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '17' });
    }
  };

  useEffect(() => {
    const offerHandler = async () => {
      const response = await getOfferRequest(offerId);
      setOffer(response.content);
      setIsLoadingOffer(false);
    };

    offerHandler();
  }, [offerId]);

  return (
    <>
      {isLoadingOffer && <LoadingData />}
      {!isLoadingOffer && (
        <Page.Content>
          <Button
            onClick={() => history.push(routes.listingOffer)}
            icon="arrow-left"
            color="secondary"
            className="mb-4"
          >
            {t('dashboard.back')}
          </Button>
          <Card>
            <Card.Body>
              <UpdateOfferForm
                handleClick={updateOffer}
                offer={offer}
                handleDisableOffer={handleDisableOffer}
                loading={loading}
              />
            </Card.Body>
          </Card>
        </Page.Content>
      )}
    </>
  );
};

export default UpdateOfferPage;
