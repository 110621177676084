import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Site, Dropdown } from 'tabler-react';
import { auth } from 'firebase';
import { useTranslation } from 'react-i18next';

import routes from '../../utils/routes';
import logo from '../../assets/images/logo.png';
import UserContext from '../../context/UserContext';

import Avatar from './Avatar';
import MainNavigation from './MainNavigation';

const Header = () => {
  const { location } = useHistory();
  const { userData } = useContext(UserContext);
  const { push } = useHistory();
  const { t } = useTranslation();

  const [collapse, setCollapse] = useState(true);

  const simpleHeader =
    location.pathname === routes.callbackStripe || !userData.isProfileComplete;
  const isOnboarding = location.pathname.includes('onboarding');

  const handleClick = () => {
    push(routes.updateProfile);
  };

  return (
    <>
      <Site.Header>
        <Site.Logo src={logo} href="/" />
        {!simpleHeader && (
          <>
            <div className="d-flex order-lg-2 ml-auto">
              <Dropdown
                isNavLink
                triggerClassName="pr-0 leading-none"
                position="bottom-end"
                triggerContent={
                  <>
                    <Avatar imageURL={userData.avatar} />
                    <span className="ml-2 d-none d-lg-block">
                      <span className="text-default">{`${userData.firstname} ${userData.lastname}`}</span>
                    </span>
                  </>
                }
                itemsObject={[
                  {
                    value: t('dashboard.navigation.profile'),
                    icon: 'user',
                    onClick: handleClick,
                  },
                  { isDivider: true },
                  {
                    value: t('dashboard.navigation.logout'),
                    icon: 'log-out',
                    onClick: () => auth().signOut(),
                  },
                ]}
              />
            </div>

            {!isOnboarding && (
              // eslint-disable-next-line
              <a
                className="header-toggler d-lg-none ml-3 ml-lg-0"
                onClick={() => setCollapse(!collapse)}
              >
                <span className="header-toggler-icon" />
              </a>
            )}
          </>
        )}
      </Site.Header>
      {!isOnboarding && !simpleHeader && (
        <Site.Nav
          collapse={collapse}
          items={<MainNavigation setCollapse={setCollapse} />}
        />
      )}
    </>
  );
};

export default Header;
