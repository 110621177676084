import React from 'react';
import { useHistory } from 'react-router-dom';
import { Nav } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import routes from '../../utils/routes';

const MainNavigation = ({ setCollapse }) => {
  const { location, push } = useHistory();
  const [t] = useTranslation();

  const menu = [
    { title: t('dashboard.navigation.stripe'), path: routes.stripe },
    {
      title: t('dashboard.navigation.average_week'),
      path: routes.averageWeek,
    },
    { title: t('dashboard.navigation.offers'), path: routes.listingOffer },
    {
      title: t('dashboard.navigation.certifications'),
      path: routes.certifications,
    },
  ];

  const handleClick = path => {
    push(path);
    setCollapse(true);
  };

  return (
    <>
      {menu.map((step, i) => (
        <Nav.Item
          key={i}
          onClick={() => handleClick(step.path)}
          active={location.pathname === step.path}
          className="py-md-4 py-2"
        >
          {step.title}
        </Nav.Item>
      ))}
    </>
  );
};

MainNavigation.propTypes = {
  setCollapse: PropTypes.func,
};

export default MainNavigation;
