import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import 'tabler-react/dist/Tabler.css';
import { omit } from 'ramda';
import { Card, Page } from 'tabler-react';

import UserContext from '../context/UserContext';
import { coachUpdateMeRequest, uploadAvatar } from '../utils/apiClient';

import UpdateProfileForm from './profile/UpdateProfileForm';

const UpdateProfilePage = () => {
  const { userData, setUserData, setMessage } = useContext(UserContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const updateCoach = async data => {
    try {
      setLoading(true);

      if (data.avatar) {
        await uploadAvatar(data.avatar);
      }

      const response = await coachUpdateMeRequest(omit('avatar', data));

      setUserData(response.content);
      setLoading(false);
      history.push('/');
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '13' });
      setLoading(false);
    }
  };

  return (
    <Page.Content>
      <Card>
        <Card.Body>
          <UpdateProfileForm
            handleClick={updateCoach}
            userData={userData}
            loading={loading}
          />
        </Card.Body>
      </Card>
    </Page.Content>
  );
};

export default UpdateProfilePage;
