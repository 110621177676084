import React, { useContext, useEffect, useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card } from 'tabler-react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { coachCreateOfferRequest, sportsRequest } from '../utils/apiClient';
import routes from '../utils/routes';
import UserContext from '../context/UserContext';

import OnboardingSteps from './layout/OnboardingSteps';
import CreateOfferForm from './offer/CreateOfferForm';
import LoadingData from './layout/LoadingData';

const CreateOfferOnboardingPage = () => {
  const history = useHistory();
  const { setMessage } = useContext(UserContext);
  const { t } = useTranslation();

  const [sports, setSports] = useState([]);
  const [isLoadingSports, setIsLoadingSports] = useState(true);
  const [loading, setLoading] = useState(false);

  const createOffer = async data => {
    try {
      setLoading(true);

      await coachCreateOfferRequest(data.sportId)(data);

      setLoading(false);
      history.push(routes.finishOnboarding);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '12' });
      setLoading(false);
    }
  };

  useEffect(() => {
    const sportsHandler = async () => {
      const response = await sportsRequest();
      setSports(response.content);
      setIsLoadingSports(false);
    };

    sportsHandler();
  }, []);

  return (
    <>
      {isLoadingSports && <LoadingData />}
      {!isLoadingSports && (
        <>
          <OnboardingSteps />
          <Page.Content>
            <Card>
              <Card.Body>
                <p>{t('offer.create.explanation')}</p>
                <CreateOfferForm
                  handleClick={createOffer}
                  isOnboarding
                  sports={sports}
                  loading={loading}
                />
              </Card.Body>
            </Card>
          </Page.Content>
        </>
      )}
    </>
  );
};

export default CreateOfferOnboardingPage;
