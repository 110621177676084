import React, { useEffect, useContext } from 'react';
import 'tabler-react/dist/Tabler.css';
import queryString from 'query-string';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import { linkStripeAccountRequest } from '../../utils/apiClient';
import UserContext from '../../context/UserContext';
import routes from '../../utils/routes';
import LoadingData from '../layout/LoadingData';

const Callback = ({ isOnboarding }) => {
  const history = useHistory();
  const { refreshUserData, setMessage, message, userData } = useContext(
    UserContext
  );

  useEffect(() => {
    const linkStripeAccount = async () => {
      const parsedQS = queryString.parse(history.location.search);

      if (!message && !userData.connected) {
        try {
          await linkStripeAccountRequest({ authorizationCode: parsedQS.code });
          await refreshUserData();

          history.push(
            isOnboarding ? routes.onboardingAverageWeek : routes.stripe
          );
          setMessage({ code: 'success' });
        } catch (e) {
          setMessage({ code: '15' });
        }
      }
    };

    linkStripeAccount();
  }, [message, history, isOnboarding, setMessage, refreshUserData, userData]);

  return <LoadingData />;
};

Callback.propTypes = {
  isOnboarding: PropTypes.bool,
};

Callback.defaultProps = {
  isOnboarding: false,
};

export default Callback;
