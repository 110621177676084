import React from 'react';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import '../../assets/css/phoneInput.css';
import PhoneInput from 'react-phone-number-input';
import fr from 'react-phone-number-input/locale/fr';

const PhoneNumberInput = ({ value, setFieldValue, error }) => (
  <PhoneInput
    country="CH"
    value={value}
    international={false}
    labels={fr}
    error={error}
    onChange={data => setFieldValue('phone', data)}
  />
);

PhoneNumberInput.propTypes = {
  value: PropTypes.string,
  setFieldValue: PropTypes.func,
  error: PropTypes.string,
};

export default PhoneNumberInput;
