import React from 'react';
import PropTypes from 'prop-types';
import { Table, Header } from 'tabler-react';
import { useTranslation } from 'react-i18next';

const CertificationListing = ({ certifiedSports }) => {
  const { t } = useTranslation();

  return (
    <>
      <Header.H4>{t('certifications.sports.title')}</Header.H4>
      <Table>
        <Table.Body>
          {certifiedSports.map((sport, i) => (
            <Table.Row key={i}>
              <Table.Col className="font-weight-bold">{sport.name}</Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

CertificationListing.propTypes = {
  certifiedSports: PropTypes.array,
};

export default CertificationListing;
