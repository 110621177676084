import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Alert } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

const StripeStatusAlert = ({ connected, payable }) => {
  const [t] = useTranslation();

  return (
    <>
      {!connected && !payable && (
        <Alert type="info" icon="info">
          {t('dashboard.no_stripe')}
        </Alert>
      )}
      {connected && !payable && (
        <Alert type="warning" icon="bell">
          {t('dashboard.has_stripe_payouts_disabled')}
        </Alert>
      )}
      {connected && payable && (
        <Alert type="success" icon="check">
          {t('dashboard.has_stripe')}
        </Alert>
      )}
    </>
  );
};

StripeStatusAlert.propTypes = {
  connected: PropTypes.bool,
  payable: PropTypes.bool,
};

export default StripeStatusAlert;
