import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import routes from '../utils/routes';

import OnboardingSteps from './layout/OnboardingSteps';
import StripeContent from './stripe/StripeContent';

const StripePage = () => {
  const [t] = useTranslation();
  const history = useHistory();

  return (
    <>
      <OnboardingSteps />
      <Page.Content>
        <Card>
          <Card.Body>
            <p>{t('stripe.explanation')}</p>
            <StripeContent isOnboarding />
            <hr />
            <p
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: t('contact', { email: 'help@wearehitch.com' }),
              }}
            />
            <Button.List align="center" className="mt-8">
              <Button
                onClick={() => history.push(routes.onboardingAverageWeek)}
                link
              >
                {t('stripe.skip')}
              </Button>
            </Button.List>
          </Card.Body>
        </Card>
      </Page.Content>
    </>
  );
};

export default StripePage;
