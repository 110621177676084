import React, { useEffect, useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import { sportsRequest } from '../utils/apiClient';

import CertificationListing from './certifications/CertificationListing';
import LoadingData from './layout/LoadingData';

const StripePage = () => {
  const [t] = useTranslation();
  const [certifiedSports, setCertifiedSports] = useState([]);
  const [isLoadingData, setIsLoadingData] = useState(true);

  useEffect(() => {
    const getCertifiedSports = async () => {
      const { content } = await sportsRequest();
      setCertifiedSports(content.filter(sport => sport.certified));
      setIsLoadingData(false);
    };

    getCertifiedSports();
  }, []);

  return (
    <>
      {isLoadingData && <LoadingData />}
      {!isLoadingData && (
        <Page.Content>
          <Card>
            <Card.Body>
              <h4>{t('certifications.title')}</h4>
              <p>{t('certifications.explanation')}</p>
              <p
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('contact', { email: 'help@wearehitch.com' }),
                }}
              />
              <hr />
              {!certifiedSports.length && (
                <p>{t('certifications.sports.no_sports')}</p>
              )}
              {certifiedSports.length > 0 && (
                <CertificationListing certifiedSports={certifiedSports} />
              )}
            </Card.Body>
          </Card>
        </Page.Content>
      )}
    </>
  );
};

export default StripePage;
