import { auth } from 'firebase';
import axios from 'axios';

import config from '../config/config';

const createClient = options => method => endpoint => async data => {
  const client = axios.create(
    options.baseURL !== config.apiUrl
      ? {
          ...options,
          headers: {
            Authorization: `Bearer ${await auth().currentUser.getIdToken()}`,
          },
        }
      : options
  );

  const response = await client.request({
    method,
    url: endpoint,
    data,
  });

  return response.data;
};

const getNoApi = createClient({
  baseURL: `${config.apiUrl}`,
})('GET');

const get = createClient({
  baseURL: `${config.apiUrl}/api/v1`,
})('GET');

const getBlob = createClient({
  baseURL: `${config.apiUrl}/api/v1`,
  responseType: 'blob',
})('GET');

const post = createClient({
  baseURL: `${config.apiUrl}/api/v1`,
})('POST');

const put = createClient({
  baseURL: `${config.apiUrl}/api/v1`,
})('PUT');

// Gets coach profile
export const coachMeRequest = get('/coach/me');

// Gets coach avatar
export const getAvatar = path => getBlob(path)();

export const uploadAvatar = put('/coach/avatar');

// Update coach profile
export const coachUpdateMeRequest = put('/coach/me');

// Request coach st tus
export const coachRequestStatus = post('/coach/request-status');

// Gets certified sports
export const sportsRequest = get('/coach/sports');

// Create an offer
export const coachCreateOfferRequest = sportId =>
  post(`/coach/sports/${sportId}/offer`);

// Get temporary link to connect to stripe connect dashboard
export const stripeCreateLinkRequest = post('/coach/payment/create-login-link');

// Finalize stripe account creation and link coach to it
export const linkStripeAccountRequest = post('/coach/link-stripe-account');

// Get a coach default availabilities
export const getDefaultAvailabilities = get('/coach/availabilities/default');

// Update a coach default availabilities
export const updateDefaultAvailabilities = put('/coach/availabilities/default');

// Get all offer
export const getOffersRequest = get('/coach/offers');

// Update an offer
export const updateOfferRequest = offerId => put(`/coach/offers/${offerId}`);

// Get an offer
export const getOfferRequest = offerId => get(`/coach/offers/${offerId}`)();

// Disable an offer
export const disableOfferRequest = offerId =>
  put(`/coach/offers/${offerId}/disable`)();

// Get bookings
export const getBookingsRequest = get('/coach/bookings');

// Get general conditions
export const getGeneralConditionsRequest = getNoApi('/general-conditions');
