import React, { useState, useContext, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import {
  updateDefaultAvailabilities,
  getDefaultAvailabilities,
} from '../utils/apiClient';
import routes from '../utils/routes';
import UserContext from '../context/UserContext';

import OnboardingSteps from './layout/OnboardingSteps';
import AverageWeek from './average-week/AverageWeek';
import LoadingData from './layout/LoadingData';

const AverageWeekOnboardingPage = () => {
  const { setMessage } = useContext(UserContext);
  const [t] = useTranslation();
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);
  const defaultAvailabilities = {
    monday: {
      timeframes: [],
    },
    tuesday: {
      timeframes: [],
    },
    wednesday: {
      timeframes: [],
    },
    thursday: {
      timeframes: [],
    },
    friday: {
      timeframes: [],
    },
    saturday: {
      timeframes: [],
    },
    sunday: {
      timeframes: [],
    },
  };

  const [availabilities, setAvailabilities] = useState(defaultAvailabilities);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateDefaultAvailabilities(availabilities);

      setLoading(false);
      history.push(routes.onboardingCreateOffer);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '14' });
      setIsLoadingData(false);
    }
  };

  useEffect(() => {
    const getAvailabilities = async () => {
      const { content } = await getDefaultAvailabilities();

      setAvailabilities(content);
      setIsLoadingData(false);
    };

    getAvailabilities();
  }, []);

  return (
    <>
      {isLoadingData && <LoadingData />}
      {!isLoadingData && (
        <>
          <OnboardingSteps />
          <Page.Content>
            <Card>
              <Card.Body>
                <p
                  // eslint-disable-next-line react/no-danger
                  dangerouslySetInnerHTML={{
                    __html: t('average_week.explanation'),
                  }}
                />
                <AverageWeek
                  loading={loading}
                  onSubmit={onSubmit}
                  isOnboarding
                  availabilities={availabilities}
                  setAvailabilities={setAvailabilities}
                />
              </Card.Body>
            </Card>
          </Page.Content>
        </>
      )}
    </>
  );
};

export default AverageWeekOnboardingPage;
