export default {
  apiUrl: 'https://api-staging.wearehitch.com',
  stripeClientId: 'ca_FPAsknl0BmlDbjnYmomNYkeXFSBaYHsu',
  firebaseConfig: {
    apiKey: 'AIzaSyCOltnzvh5ulNK3YAsr2bu0wdUJIuJpwV8',
    authDomain: 'hitch-staging-4143e.firebaseapp.com',
    databaseURL: 'https://hitch-staging-4143e.firebaseio.com',
    projectId: 'hitch-staging-4143e',
    storageBucket: 'hitch-staging-4143e.appspot.com',
    messagingSenderId: '938352178713',
    appId: '1:938352178713:web:48d53518c6af7bb32a9c88',
    measurementId: 'G-74891LQJB6',
  },
  startHours: {
    min: '05:00',
    max: '22:00',
  },
  endHours: {
    min: '06:00',
    max: '23:00',
  },
  maxFileSize: 5000000, // 5Mb in bytes
  supportedFormats: ['image/jpeg', 'image/jpg', 'image/png'],
};
