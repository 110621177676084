import React, { useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Form } from 'tabler-react';
import PropTypes from 'prop-types';
import '../../assets/css/registrationForm.css';
import { useTranslation } from 'react-i18next';

const LocationsInput = ({ defaultLocations = [''], onChange, onBlur }) => {
  const { t } = useTranslation();
  const [locationsInputList, setLocationsInputList] = useState(
    defaultLocations
  );

  // handle click event of the Add button
  const handleAddClick = () => {
    setLocationsInputList([...locationsInputList, '']);
  };

  // handle click event of the Remove button
  const handleRemoveClick = index => {
    const list = [...locationsInputList];
    list.splice(index, 1);
    setLocationsInputList(list);
    onChange(list);
  };

  const handleLocationChange = (e, i) => {
    const { value } = e.target;
    const list = [...locationsInputList];
    list[i] = value;
    setLocationsInputList(list);
    onChange(list);
  };

  return (
    <>
      {locationsInputList.map((location, i) => (
        <>
          <div className="mb-2 d-flex" key={`location-${i}`}>
            <Form.Input
              name={`locations[${i}]`}
              value={location}
              onChange={e => {
                handleLocationChange(e, i);
              }}
              onBlur={onBlur}
            />
            {locationsInputList.length !== 1 && (
              <Button
                className="ml-2"
                color="danger"
                icon="trash"
                onClick={e => handleRemoveClick(e, i)}
              />
            )}
          </div>
          {locationsInputList.length - 1 === i && (
            <Button
              icon="plus"
              size="sm"
              color="secondary"
              onClick={handleAddClick}
            >
              {t('offer.create.locations.add')}
            </Button>
          )}
        </>
      ))}
    </>
  );
};

LocationsInput.propTypes = {
  defaultLocations: PropTypes.array,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};
export default LocationsInput;
