import React, { useState, useEffect, useContext } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button } from 'tabler-react';
import { useTranslation } from 'react-i18next';
import { parsePhoneNumber } from 'react-phone-number-input';
import { stringify } from 'query-string';
import PropTypes from 'prop-types';

import UserContext from '../../context/UserContext';
import { stripeCreateLinkRequest } from '../../utils/apiClient';
import routes from '../../utils/routes';
import config from '../../config/config';
import LoadingData from '../layout/LoadingData';
import LoadingButton from '../layout/LoadingButton';

import StripeStatusAlert from './StripeStatusAlert';

const StripeContent = ({ isOnboarding }) => {
  const { userData } = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const [queryString, setQueryString] = useState(null);
  const [t] = useTranslation();

  useEffect(() => {
    const { country, nationalNumber } = parsePhoneNumber(userData.phone);
    const url = isOnboarding
      ? `${window.location.origin}${routes.onboardingCallbackStripe}`
      : `${window.location.origin}${routes.callbackStripe}`;

    setQueryString({
      redirect_uri: url,
      client_id: config.stripeClientId,
      'stripe_user[business_type]': 'individual',
      'stripe_user[email]': userData.email,
      'stripe_user[first_name]': userData.firstname,
      'stripe_user[last_name]': userData.lastname,
      'stripe_user[country]': country,
      'stripe_user[phone_number]': nationalNumber,
    });
  }, [isOnboarding, userData]);

  const redirectToStripeAccount = async () => {
    setLoading(true);

    const stripeLink = await stripeCreateLinkRequest();
    window.open(stripeLink.content.url);

    setLoading(false);
  };

  return (
    <>
      {!userData && <LoadingData />}

      <StripeStatusAlert
        connected={userData.connected}
        payable={userData.payable}
      />

      {!userData.connected && (
        <Button
          RootComponent="a"
          target="_blank"
          color="primary"
          href={`https://connect.stripe.com/express/oauth/authorize?${stringify(
            queryString
          )}`}
        >
          {t('dashboard.link')}
        </Button>
      )}
      {userData.connected && (
        <>
          <Button
            color="primary"
            onClick={redirectToStripeAccount}
            disabled={loading}
          >
            {loading && <LoadingButton />}
            {!loading && t('dashboard.connect')}
          </Button>
        </>
      )}
    </>
  );
};

StripeContent.propTypes = {
  isOnboarding: PropTypes.bool,
};

StripeContent.defaultProps = {
  isOnboarding: false,
};

export default StripeContent;
