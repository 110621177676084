import React from 'react';
import Loader from 'react-loader-spinner';

const LoadingButton = () => (
  <Loader
    type="Oval"
    color="#ffffff"
    height={30}
    width={30}
    style={{
      lineHeight: '1',
    }}
  />
);

export default LoadingButton;
