import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Header } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import StripeContent from './stripe/StripeContent';

const StripePage = () => {
  const [t] = useTranslation();

  return (
    <Page.Content>
      <Card>
        <Card.Body>
          <Header.H4>{t('stripe.title')}</Header.H4>
          <p>{t('stripe.explanation')}</p>
          <StripeContent />
          <hr />
          <p
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: t('contact', { email: 'help@wearehitch.com' }),
            }}
          />
        </Card.Body>
      </Card>
    </Page.Content>
  );
};

export default StripePage;
