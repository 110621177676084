import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Form } from 'tabler-react';
import { useFormik } from 'formik';
import { object, string, number, array } from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

import routes from '../../utils/routes';
import LoadingButton from '../layout/LoadingButton';

import LocationsInput from './LocationsInput';
import '../../assets/css/registrationForm.css';

const CreateOfferForm = ({ handleClick, isOnboarding, sports, loading }) => {
  const [t] = useTranslation();
  const history = useHistory();

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    setFieldValue,
  } = useFormik({
    initialValues: {
      sportId: '',
      duration: 60,
      price: '',
      locations: [],
    },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        sportId: string().required(t('offer.create.sport.validation')),
        price: number()
          .typeError(t('offer.create.price.validation_format'))
          .required(t('offer.create.price.validation')),
        duration: number()
          .typeError(t('offer.create.duration.validation_format'))
          .integer(t('offer.create.duration.validation_format'))
          .required(t('offer.create.duration.validation')),
        locations: array()
          .of(string().required(t('offer.create.locations.validation')))
          .required(t('offer.create.locations.validation'))
          .min(1, t('offer.create.locations.validation')),
      }),
    onSubmit: data => {
      handleClick({
        ...data,
        locations: data.locations.filter(
          locationsInput => locationsInput.location !== ''
        ),
        price: Math.round(data.price * 100),
        duration: parseInt(data.duration),
        type: 'individual',
      });
    },
  });

  return (
    <>
      <Form onSubmit={handleSubmit}>
        <Form.Group label={t('offer.create.sport.label')}>
          {sports.length > 0 &&
            sports.map(sport => (
              <Form.Radio
                isInline
                key={sport.id}
                name="sportId"
                value={sport.id}
                label={sport.name}
                onChange={handleChange}
                onBlur={handleBlur}
              />
            ))}
          {errors.sportId && touched.sportId && (
            <p className="invalid-feedback d-block">{errors.sportId}</p>
          )}
        </Form.Group>

        <Form.Group label={t('offer.create.type.label')}>
          <Form.Checkbox
            name="type"
            value="individual"
            checked
            disabled
            label={t('offer.create.type.checkbox.label')}
            isInline
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="text-small">{t('offer.create.type.helptext')}</p>
        </Form.Group>

        <Form.Group label={t('offer.create.duration.label')}>
          <Form.Input
            name="duration"
            disabled
            feedback={
              errors.duration && touched.duration ? errors.duration : null
            }
            invalid={!!(errors.duration && touched.duration)}
            value={values.duration}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <p className="text-small mt-1">
            {t('offer.create.duration.helptext')}
          </p>
        </Form.Group>

        <Form.Group label={t('offer.create.price.label')}>
          <Form.Input
            name="price"
            type="number"
            feedback={errors.price && touched.price ? errors.price : null}
            invalid={!!(errors.price && touched.price)}
            value={values.price}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </Form.Group>

        <Form.Group label={t('offer.create.locations.label')}>
          <LocationsInput
            onChange={locations => setFieldValue('locations', locations)}
            onBlur={handleBlur}
          />
          {errors.locations && touched.locations && (
            <p className="invalid-feedback d-block">{errors.locations}</p>
          )}
        </Form.Group>

        <Button color="primary" disabled={loading} type="submit">
          {loading ? <LoadingButton /> : t('offer.create.submit')}
        </Button>
      </Form>

      {isOnboarding && (
        <Button.List align="center" className="mt-8">
          <Button onClick={() => history.push(routes.finishOnboarding)} link>
            {t('offer.create.skip')}
          </Button>
        </Button.List>
      )}
    </>
  );
};

CreateOfferForm.propTypes = {
  handleClick: PropTypes.func,
  isOnboarding: PropTypes.bool,
  sports: PropTypes.array,
  loading: PropTypes.bool,
};
CreateOfferForm.defaultProps = {
  isOnboarding: false,
};

export default CreateOfferForm;
