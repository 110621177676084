import React from 'react';
import Loader from 'react-loader-spinner';

const LoadingData = () => (
  <Loader
    type="Oval"
    color="#999999"
    height={100}
    width={100}
    style={{
      position: 'absolute',
      top: 'calc(50% - 50px)',
      left: 'calc(50% - 50px)',
    }}
  />
);

export default LoadingData;
