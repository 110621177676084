import React, { useState, useContext, useEffect } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Page, Card, Header } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import {
  updateDefaultAvailabilities,
  getDefaultAvailabilities,
} from '../utils/apiClient';
import UserContext from '../context/UserContext';

import AverageWeek from './average-week/AverageWeek';
import LoadingData from './layout/LoadingData';

const AverageWeekPage = () => {
  const { setMessage } = useContext(UserContext);
  const [t] = useTranslation();

  const [loading, setLoading] = useState(false);
  const [refreshAvailabilities, setRefreshAvailabilities] = useState(false);
  const [isLoadingData, setIsLoadingData] = useState(true);

  const defaultAvailabilities = {
    monday: {
      timeframes: [],
    },
    tuesday: {
      timeframes: [],
    },
    wednesday: {
      timeframes: [],
    },
    thursday: {
      timeframes: [],
    },
    friday: {
      timeframes: [],
    },
    saturday: {
      timeframes: [],
    },
    sunday: {
      timeframes: [],
    },
  };

  const [availabilities, setAvailabilities] = useState(defaultAvailabilities);

  const onSubmit = async () => {
    try {
      setLoading(true);

      await updateDefaultAvailabilities(availabilities);

      setRefreshAvailabilities(true);
      setLoading(false);
      setMessage({ code: 'success' });
    } catch (e) {
      setMessage({ code: '14' });
      setLoading(false);
    }
  };

  useEffect(() => {
    const getAvailabilities = async () => {
      const { content } = await getDefaultAvailabilities();

      setAvailabilities(content);
      setIsLoadingData(false);
      setRefreshAvailabilities(false);
    };

    getAvailabilities();
  }, [refreshAvailabilities]);

  return (
    <>
      {isLoadingData && <LoadingData />}
      {!isLoadingData && (
        <Page.Content>
          <Card>
            <Card.Body>
              <Header.H4>{t('average_week.title')}</Header.H4>
              <p // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{
                  __html: t('average_week.explanation'),
                }}
              />
              <AverageWeek
                loading={loading}
                onSubmit={onSubmit}
                availabilities={availabilities}
                setAvailabilities={setAvailabilities}
              />
            </Card.Body>
          </Card>
        </Page.Content>
      )}
    </>
  );
};

export default AverageWeekPage;
