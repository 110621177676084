import React, { useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Card, Form, Avatar as TablerAvatar, Grid } from 'tabler-react';
import { useFormik } from 'formik';
import { object, string, array, mixed } from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';

import languages from '../../locales/languages.fr';
import LoadingButton from '../layout/LoadingButton';
import Avatar from '../layout/Avatar';
import config from '../../config/config';

import '../../assets/css/registrationForm.css';

const UpdateProfileForm = ({ handleClick, userData, loading }) => {
  const [t] = useTranslation();

  const [tmpAvatarUrl, setTmpAvatarUrl] = useState(null);

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      avatar: '',
      avatarFile: null,
      firstname: userData.firstname,
      lastname: userData.lastname,
      description: userData.description,
      gender: {
        value: userData.gender,
        label: t(`coach.genders.${userData.gender}`),
      },
      spokenLanguages: userData.spokenLanguages.map(language => ({
        value: language,
        label: languages[language],
      })),
    },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        avatar: string(),
        avatarFile: mixed()
          .test('fileSize', t('coach.avatar.validation_size'), value =>
            value ? value.size <= config.maxFileSize : true
          )
          .test('fileType', t('coach.avatar.validation_type'), value =>
            config.supportedFormats.includes(value ? value.type : 'image/jpeg')
          ),
        firstname: string().required(t('coach.firstname.validation')),
        lastname: string().required(t('coach.lastname.validation')),
        description: string()
          .typeError(t('coach.description.validation'))
          .required(t('coach.description.validation')),
        gender: string().required(t('coach.gender.validation')),
        spokenLanguages: array()
          .min(1, t('coach.spokenLanguages.validation'))
          .of(string()),
      }),
    onSubmit: data => {
      handleClick({
        ...data,
        gender: data.gender.value,
        avatar: data.avatarFile,
        spokenLanguages: data.spokenLanguages.map(language => language.value),
      });
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Title>{t('profile.title')}</Card.Title>
      <Form.Group label={t('coach.avatar.label')}>
        <Grid.Row alignItems="center">
          <Grid.Col width={3}>
            {tmpAvatarUrl && (
              <TablerAvatar imageURL={tmpAvatarUrl} size="xxl" />
            )}
            {!tmpAvatarUrl && <Avatar imageURL={userData.avatar} size="xxl" />}
          </Grid.Col>
          <Grid.Col width={9}>
            <Form.FileInput
              name="avatar"
              value={values.avatar}
              onChange={event => {
                setFieldValue('avatarFile', event.currentTarget.files[0]);

                setTmpAvatarUrl(
                  URL.createObjectURL(event.currentTarget.files[0])
                );

                handleChange(event);
              }}
              onBlur={handleBlur}
            />
            {errors.avatar && touched.avatar && (
              <p className="invalid-feedback d-block">{errors.avatar}</p>
            )}
            {errors.avatarFile && (
              <p className="invalid-feedback d-block">{errors.avatarFile}</p>
            )}
          </Grid.Col>
        </Grid.Row>
      </Form.Group>
      <Form.Group label={t('coach.firstname.label')}>
        <Form.Input
          name="firstname"
          feedback={
            errors.firstname && touched.firstname ? errors.firstname : null
          }
          invalid={!!(errors.firstname && touched.firstname)}
          value={values.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.lastname.label')}>
        <Form.Input
          name="lastname"
          feedback={
            errors.lastname && touched.lastname ? errors.lastname : null
          }
          invalid={!!(errors.lastname && touched.lastname)}
          value={values.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.description.label')}>
        <Form.Textarea
          name="description"
          feedback={
            errors.description && touched.description
              ? errors.description
              : null
          }
          invalid={!!(errors.description && touched.description)}
          value={values.description}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.gender.label')}>
        <Select
          className="registration-select"
          onChange={selectValues => setFieldValue('gender', selectValues)}
          value={values.gender}
          options={[
            { value: 'f', label: t('coach.genders.f') },
            { value: 'm', label: t('coach.genders.m') },
            { value: 'o', label: t('coach.genders.o') },
          ]}
        />
      </Form.Group>

      <Form.Group label={t('coach.spokenLanguages.label')}>
        <Select
          className="registration-select"
          isMulti
          onChange={selectValues =>
            setFieldValue('spokenLanguages', selectValues)
          }
          value={values.spokenLanguages}
          options={Object.entries(languages).map(([key, value]) => ({
            value: key,
            label: value,
          }))}
        />
      </Form.Group>

      <Button color="primary" disabled={loading} type="submit">
        {loading ? <LoadingButton /> : t('profile.submit')}
      </Button>
    </Form>
  );
};

UpdateProfileForm.propTypes = {
  userData: PropTypes.object,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default UpdateProfileForm;
