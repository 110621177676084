import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert as AlertTabler } from 'tabler-react';
import PropTypes from 'prop-types';

import UserContext from '../../context/UserContext';

const Alert = ({ onClick, message }) => {
  const { t } = useTranslation();
  const { setMessage } = useContext(UserContext);
  const [visible, setVisible] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setVisible(false);
      setMessage(null);
    }, 3000);
  }, [setVisible, setMessage]);

  return (
    <div className="fixed-top w-100 z-index-2">
      {message.code !== 'success' && (
        <AlertTabler type="danger" isDismissible onDismissClick={onClick}>
          {t('error.message')}
          {` (code:  ERROR_${message.code})`}
        </AlertTabler>
      )}
      {message.code === 'success' && visible && (
        <AlertTabler type="success" isDismissible onDismissClick={onClick}>
          {t('success.message')}
        </AlertTabler>
      )}
    </div>
  );
};

Alert.propTypes = {
  message: PropTypes.array,
  onClick: PropTypes.func,
};

export default Alert;
