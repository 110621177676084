import React from 'react';
import { useHistory } from 'react-router-dom';
import { Nav } from 'tabler-react';
import { useTranslation } from 'react-i18next';

import routes from '../../utils/routes';

const OnboardingSteps = () => {
  const { location, push } = useHistory();
  const [t] = useTranslation();

  const steps = [
    { title: t('onboarding.steps.stripe'), path: routes.onboardingStripe },
    {
      title: t('onboarding.steps.average_week'),
      path: routes.onboardingAverageWeek,
    },
    { title: t('onboarding.steps.offer'), path: routes.onboardingCreateOffer },
    {
      title: t('onboarding.steps.confirmation'),
      path: routes.finishOnboarding,
    },
  ];

  return (
    <div className="page page-single">
      <div className="container row">
        <div className="col mx-auto">
          <Nav className="d-md-flex d-block">
            {steps.map((step, i) => (
              <Nav.Item
                key={i}
                onClick={() => push(step.path)}
                active={location.pathname === step.path}
                className="py-md-4 py-2"
              >
                {step.title}
              </Nav.Item>
            ))}
          </Nav>
        </div>
      </div>
    </div>
  );
};

export default OnboardingSteps;
