import React, { useState } from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Card, Form, Avatar as TablerAvatar, Grid } from 'tabler-react';
import { Link } from 'react-router-dom';
import { useFormik } from 'formik';
import { object, string, array, mixed } from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Select from 'react-select';

import languages from '../../locales/languages.fr';
import routes from '../../utils/routes';
import LoadingButton from '../layout/LoadingButton';
import Avatar from '../layout/Avatar';
import config from '../../config/config';

import '../../assets/css/registrationForm.css';

const ProfileForm = ({ handleClick, avatar, loading }) => {
  const [t] = useTranslation();

  const [tmpAvatarUrl, setTmpAvatarUrl] = useState(null);

  const {
    values,
    handleBlur,
    handleChange,
    setFieldValue,
    handleSubmit,
    errors,
    touched,
  } = useFormik({
    initialValues: {
      avatar: '',
      avatarFile: null,
      firstname: '',
      lastname: '',
      gender: {
        value: 'f',
        label: t('coach.genders.f'),
      },
      email: '',
      spokenLanguages: [
        {
          value: 'fr',
          label: languages.fr,
        },
      ],
      conditions: [],
    },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        avatar: string().required(t('coach.avatar.validation')),
        avatarFile: mixed()
          .test('fileSize', t('coach.avatar.validation_size'), value =>
            value ? value.size <= config.maxFileSize : true
          )
          .test('fileType', t('coach.avatar.validation_type'), value =>
            config.supportedFormats.includes(value ? value.type : 'image/jpeg')
          ),
        firstname: string().required(t('coach.firstname.validation')),
        lastname: string().required(t('coach.lastname.validation')),
        gender: string().required(t('coach.gender.validation')),
        email: string()
          .email(t('coach.email.validation_format'))
          .required(t('coach.email.validation')),
        spokenLanguages: array()
          .typeError(t('coach.spokenLanguages.validation'))
          .min(1, t('coach.spokenLanguages.validation'))
          .of(string()),
        conditions: array()
          .min(1, t('coach.conditions.validation'))
          .of(string()),
      }),
    onSubmit: data => {
      handleClick({
        ...data,
        gender: data.gender.value,
        avatar: data.avatarFile,
        spokenLanguages: data.spokenLanguages.map(language => language.value),
      });
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Title>{t('profile.title')}</Card.Title>
      <Form.Group label={t('coach.avatar.label')}>
        <Grid.Row alignItems="center">
          <Grid.Col width={3}>
            {tmpAvatarUrl && (
              <TablerAvatar imageURL={tmpAvatarUrl} size="xxl" />
            )}
            {!tmpAvatarUrl && <Avatar imageURL={avatar} size="xxl" />}
          </Grid.Col>
          <Grid.Col width={9}>
            <Form.FileInput
              name="avatar"
              label={t('coach.avatar.input_label')}
              value={values.avatar}
              onChange={event => {
                setFieldValue('avatarFile', event.currentTarget.files[0]);

                setTmpAvatarUrl(
                  URL.createObjectURL(event.currentTarget.files[0])
                );

                handleChange(event);
              }}
              onBlur={handleBlur}
            />
            <p className="text-small mt-1">{t('coach.avatar.helptext')}</p>
            {errors.avatar && touched.avatar && (
              <p className="invalid-feedback d-block">{errors.avatar}</p>
            )}
            {errors.avatarFile && (
              <p className="invalid-feedback d-block">{errors.avatarFile}</p>
            )}
          </Grid.Col>
        </Grid.Row>
      </Form.Group>
      <Form.Group label={t('coach.firstname.label')}>
        <Form.Input
          name="firstname"
          feedback={
            errors.firstname && touched.firstname ? errors.firstname : null
          }
          invalid={!!(errors.firstname && touched.firstname)}
          value={values.firstname}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.lastname.label')}>
        <Form.Input
          name="lastname"
          feedback={
            errors.lastname && touched.lastname ? errors.lastname : null
          }
          invalid={!!(errors.lastname && touched.lastname)}
          value={values.lastname}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.gender.label')}>
        <Select
          className="registration-select"
          onChange={selectValues => setFieldValue('gender', selectValues)}
          value={values.gender}
          options={[
            { value: 'f', label: t('coach.genders.f') },
            { value: 'm', label: t('coach.genders.m') },
            { value: 'o', label: t('coach.genders.o') },
          ]}
        />
      </Form.Group>

      <Form.Group label={t('coach.email.label')}>
        <Form.Input
          name="email"
          feedback={errors.email && touched.email ? errors.email : null}
          invalid={!!(errors.email && touched.email)}
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
        />
      </Form.Group>

      <Form.Group label={t('coach.spokenLanguages.label')}>
        <Select
          className="registration-select"
          isMulti
          onChange={selectValues =>
            setFieldValue('spokenLanguages', selectValues)
          }
          value={values.spokenLanguages}
          options={Object.entries(languages).map(([key, value]) => ({
            value: key,
            label: value,
          }))}
        />
        {errors.spokenLanguages && touched.spokenLanguages && (
          <p className="invalid-feedback d-block">{errors.spokenLanguages}</p>
        )}
      </Form.Group>

      <Form.Group>
        <Form.Checkbox
          className="conditions-checkbox"
          label={t('coach.conditions.accept')}
          style={{ marginRight: 0 }}
          isInline
          name="conditions"
          onChange={handleChange}
          value="accepted"
        />
        <Link to={routes.generalConditions} target="_blank">
          {t('coach.conditions.generalConditions')}
        </Link>
        .
        {errors.conditions && touched.conditions && (
          <p className="invalid-feedback d-block">{errors.conditions}</p>
        )}
      </Form.Group>
      <Button color="primary" disabled={loading} type="submit">
        {loading ? <LoadingButton /> : t('profile.submit')}
      </Button>
    </Form>
  );
};

ProfileForm.propTypes = {
  avatar: PropTypes.string,
  handleClick: PropTypes.func,
  loading: PropTypes.bool,
};

export default ProfileForm;
