import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Table, Icon } from 'tabler-react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import routes from '../../utils/routes';

const IncompleteOnboardingReminder = ({
  isProfileComplete,
  isStripePayable,
  hasOffers,
  hasCertifiedSports,
}) => {
  const { t } = useTranslation();
  const { push } = useHistory();

  const links = [
    {
      title: t('dashboard.incomplete_profile.steps.profile'),
      href: routes.updateProfile,
      complete: isProfileComplete,
    },
    {
      title: t('dashboard.incomplete_profile.steps.stripe'),
      href: routes.stripe,
      complete: isStripePayable,
    },
    {
      title: t('dashboard.incomplete_profile.steps.offer'),
      href: routes.listingOffer,
      complete: hasOffers,
    },
    {
      title: t('dashboard.incomplete_profile.steps.certifications'),
      href: routes.certifications,
      complete: hasCertifiedSports,
    },
  ];

  return (
    <>
      <h4>{t('dashboard.incomplete_profile.title')}</h4>
      <p>{t('dashboard.incomplete_profile.explanation')}</p>

      <Table className="mt-3">
        <Table.Body>
          {links.map((link, key) => (
            <Table.Row
              key={key}
              onClick={() => {
                push(link.href);
              }}
              className="hoverEffect"
            >
              <Table.Col>
                {link.complete && (
                  <Icon name="check-circle" className="mr-2 text-success" />
                )}
                {!link.complete && (
                  <Icon name="x-circle" className="mr-2 text-danger" />
                )}
                <span className="link">{link.title}</span>
              </Table.Col>
              <Table.Col className="text-right">
                <Icon name="chevron-right" />
              </Table.Col>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </>
  );
};

IncompleteOnboardingReminder.propTypes = {
  isProfileComplete: PropTypes.bool,
  isStripePayable: PropTypes.bool,
  hasOffers: PropTypes.bool,
  hasCertifiedSports: PropTypes.bool,
};

export default IncompleteOnboardingReminder;
