import React from 'react';
import { useFormik } from 'formik';
import 'tabler-react/dist/Tabler.css';
import { Button, Form, Card } from 'tabler-react';
import { string, object } from 'yup';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import LoadingButton from '../layout/LoadingButton';

import PhoneNumberInput from './PhoneNumberInput';

const LoginForm = ({ handleClick }) => {
  const [t] = useTranslation();

  const {
    values,
    handleSubmit,
    setFieldValue,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: { phone: '' },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        phone: string()
          .required(t('coach.phone.validation'))
          .matches(/^[+][0-9]{11}$/, {
            message: t('coach.phone.validation_format'),
          }),
      }),
    onSubmit: data => {
      handleClick(data.phone);
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Title>{t('login.title')}</Card.Title>
      <Form.Group label={t('coach.phone.label')}>
        <PhoneNumberInput
          value={values.phone}
          setFieldValue={setFieldValue}
          error={errors.phone && touched.phone ? errors.phone : null}
        />
      </Form.Group>
      <Button.List>
        <Button color="primary" disabled={isSubmitting} type="submit">
          {isSubmitting ? <LoadingButton /> : t('login.submit')}
        </Button>
        <Button color="link" disabled={isSubmitting} type="submit">
          {isSubmitting ? <LoadingButton /> : t('login.register')}
        </Button>
      </Button.List>
    </Form>
  );
};

LoginForm.propTypes = {
  handleClick: PropTypes.func,
};

export default LoginForm;
