import React, { useEffect, useContext } from 'react';
import { Switch, Route, useHistory } from 'react-router-dom';
import { auth } from 'firebase';
import 'tabler-react/dist/Tabler.css';

import { coachRequestStatus, coachMeRequest } from '../utils/apiClient';
import UserContext from '../context/UserContext';
import routes from '../utils/routes';

import GeneralConditions from './GeneralConditions';
import Header from './layout/Header';
import Callback from './stripe/Callback';
import CreateOfferPage from './CreateOfferPage';
import OfferListingPage from './OfferListingPage';
import AverageWeekPage from './AverageWeekPage';
import AverageWeekOnboardingPage from './AverageWeekOnboardingPage';
import ProfilePage from './ProfilePage';
import DashboardContent from './DashboardContent';
import StripePage from './StripePage';
import StripeOnboardingPage from './StripeOnboardingPage';
import EndOnboardingPage from './EndOnboardingPage';
import CreateOfferOnboardingPage from './CreateOfferOnboardingPage';
import LoadingData from './layout/LoadingData';
import UpdateOfferPage from './UpdateOfferPage';
import CertificationsPage from './CertificationsPage';
import UpdateProfilePage from './UpdateProfilePage';

const Dashboard = () => {
  const { userData, setUserData, setMessage } = useContext(UserContext);
  const history = useHistory();

  useEffect(() => {
    const coachStatusHandler = async () => {
      const token = await auth().currentUser.getIdTokenResult();

      if (!token.claims.coach) {
        await coachRequestStatus();
        await auth().currentUser.getIdToken(true);
      }

      const response = await coachMeRequest();
      setUserData(response.content);
    };

    coachStatusHandler();

    // When changing page, ensure that message is null to avoid displaying an alert
    history.listen(() => {
      setMessage(null);
    });
  }, [history, setUserData, setMessage]);

  return (
    <div>
      {userData && (
        <>
          <Header />
          <Route
            exact
            path={routes.generalConditions}
            component={GeneralConditions}
          />
          {!userData.isProfileComplete && (
            <Switch>
              <Route path="/" component={ProfilePage} />
            </Switch>
          )}
          {userData.isProfileComplete && (
            <Switch>
              <Route exact path="/" component={DashboardContent} />
              <Route exact path={routes.stripe} component={StripePage} />
              <Route
                exact
                path={routes.onboardingStripe}
                component={StripeOnboardingPage}
              />
              <Route exact path={routes.callbackStripe} component={Callback} />
              <Route
                exact
                path={routes.onboardingCallbackStripe}
                component={() => <Callback isOnboarding />}
              />
              <Route
                exact
                path={routes.averageWeek}
                component={AverageWeekPage}
              />
              <Route
                exact
                path={routes.onboardingAverageWeek}
                component={AverageWeekOnboardingPage}
              />
              <Route
                exact
                path={routes.createOffer}
                component={CreateOfferPage}
              />
              <Route
                exact
                path={routes.onboardingCreateOffer}
                component={CreateOfferOnboardingPage}
              />
              <Route
                exact
                path={routes.finishOnboarding}
                component={EndOnboardingPage}
              />
              <Route
                exact
                path={routes.listingOffer}
                component={OfferListingPage}
              />
              <Route
                exact
                path={routes.showOffer}
                component={UpdateOfferPage}
              />
              <Route
                exact
                path={routes.updateProfile}
                component={UpdateProfilePage}
              />
              <Route
                exact
                path={routes.certifications}
                component={CertificationsPage}
              />
            </Switch>
          )}
        </>
      )}
      {!userData && <LoadingData />}
    </div>
  );
};

export default Dashboard;
