export default {
  loginConfirmation: '/confirmation',
  generalConditions: '/general-conditions',
  stripe: '/stripe',
  onboardingStripe: '/onboarding/stripe',
  callbackStripe: '/callback',
  onboardingCallbackStripe: '/onboarding/callback',
  averageWeek: '/average-week',
  onboardingAverageWeek: '/onboarding/average-week',
  createOffer: '/offers/create',
  onboardingCreateOffer: '/onboarding/offers/create',
  finishOnboarding: '/onboarding/finish',
  listingOffer: '/offers',
  showOffer: '/offers/:offerId',
  certifications: '/certifications',
  updateProfile: '/profile',
};
