import React from 'react';
import 'tabler-react/dist/Tabler.css';
import { Button, Card, Form } from 'tabler-react';
import PropTypes from 'prop-types';
import { useFormik } from 'formik';
import { object, string } from 'yup';
import { useTranslation } from 'react-i18next';

import LoadingButton from '../layout/LoadingButton';

const ConfirmationForm = ({ handleClick }) => {
  const [t] = useTranslation();

  const {
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    errors,
    touched,
    isSubmitting,
  } = useFormik({
    initialValues: { code: '' },
    mapPropsToValues: () => ({}),
    validationSchema: () =>
      object().shape({
        code: string()
          .required(t('confirmation.code.validation'))
          .length(6, t('confirmation.code.validation_format')),
      }),
    onSubmit: data => {
      handleClick(data.code.toString());
    },
  });

  return (
    <Form onSubmit={handleSubmit}>
      <Card.Title>{t('confirmation.title')}</Card.Title>
      <p>{t('confirmation.description')}</p>
      <Form.Group label={t('confirmation.code.label')}>
        <Form.Input
          feedback={errors.code && touched.code ? errors.code : null}
          invalid={!!(errors.code && touched.code)}
          name="code"
          type="number"
          value={values.code}
          onChange={handleChange}
          onBlur={handleBlur}
          placeholder="000000"
        />
      </Form.Group>
      <Button color="primary" disabled={isSubmitting} type="submit">
        {isSubmitting ? <LoadingButton /> : t('confirmation.submit')}
      </Button>
    </Form>
  );
};

ConfirmationForm.propTypes = {
  handleClick: PropTypes.func,
};

export default ConfirmationForm;
